import React, { useEffect, useState } from "react";
import MikkaLogoDark from "../assets/images/mikka-logo-dark.svg";
import MikkaLogoWhite from "../assets/images/mikka-logo-white.svg";
import MikkaLogoTransparent from "../assets/images/MIKKA_Logo_transparent.png";
import MikkaLogoLAGERWhite from "../assets/images/mikka_lager_weiss.png";

const Logo = ({ color = "transparent", height = 40 }) => {
  const [logoSrc, setLogoSrc] = useState(MikkaLogoTransparent);
  useEffect(() => {
    switch (color) {
      case "transparent":
        setLogoSrc(MikkaLogoTransparent);
        break;
      case "dark":
        setLogoSrc(MikkaLogoDark);
        break;
      case "white":
        setLogoSrc(MikkaLogoWhite);
        break;
      case "lagerWhite":
        setLogoSrc(MikkaLogoLAGERWhite);
        break;
      default:
        setLogoSrc(MikkaLogoLAGERWhite);
    }
  }, [logoSrc, color]);
  return (
    <div>
      <img src={logoSrc} style={{ height: height }} alt="MIKKA" />
    </div>
  );
};
export default Logo;

import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import Routes from "./routes";
import AuthRoutes from "./routes/AuthRoutes";

// defaultTheme
import themes from "./themes";

// project imports
import NavigationScroll from "./layout/NavigationScroll";
import { Component } from "react";

import authController from "./controller/auth";
import * as PropTypes from "prop-types";
import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  useRoutes,
} from "react-router-dom";
import Initializing from "./views/pages/initializing";
import Login from "./views/pages/authentication/authentication3/Login3";
import MinimalLayout from "./layout/MinimalLayout";
import MainRoutes from "./routes/MainRoutes";

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

// ==============================|| APP ||============================== //

class App extends Component {
  // const customization = useSelector((state) => state.customization);
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      customization: null,
    };
  }

  componentDidMount() {
    // Register an observer for the authController
    authController.addHandler(this);

    // Test password-less login as user might be already logged in.
    // If successful, the authController calls onAuthChanged
    // In any case, set initialized to true, which triggers the render method

    console.log("x1");
    authController
      .login()
      .then((user) => this.setState({ initialized: true }))
      .catch((error) => this.setState({ initialized: true }));

    console.log("x2");
  }

  onAuthChanged(args) {
    this.setState({ user: args.user });
  }

  render() {
    if (!this.state.initialized) {
      return <Initializing />;
    }

    // Routes if user is already authenticated
    if (authController.isLoggedIn()) {
      console.log("App.js -> authController is logged in");
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(this.customization)}>
            <CssBaseline />
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </ThemeProvider>
        </StyledEngineProvider>
      );
    }

    if (!authController.isLoggedIn()) {
      console.log("App.js -> authController is NOT logged in");
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(this.customization)}>
            <CssBaseline />
            <NavigationScroll>
              <AuthRoutes />
            </NavigationScroll>
          </ThemeProvider>
        </StyledEngineProvider>
        // <StyledEngineProvider injectFirst>
        //   <ThemeProvider theme={themes(this.customization)}>
        //     <CssBaseline />
        // <NavigationScroll>
        // <Routes>
        //   <MinimalLayout>
        //   <Route path="/logout" element={<Navigate to="/" />} />
        //   <Route path="*" element={<Login />} />
        //   </MinimalLayout>
        // </Routes>
        // </NavigationScroll>
        //   </ThemeProvider>
        // </StyledEngineProvider>
      );
    }
  }
}

// const App = () => {
//   const customization = useSelector((state) => state.customization);
//
//   return (
//     <StyledEngineProvider injectFirst>
//       <ThemeProvider theme={themes(customization)}>
//         <CssBaseline />
//         <NavigationScroll>
//           <Routes />
//         </NavigationScroll>
//       </ThemeProvider>
//     </StyledEngineProvider>
//   );
// };

export default App;

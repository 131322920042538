import React, { useEffect, useState } from "react";
import CategoryIcon from "@mui/icons-material/Category";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MonitorIcon from "@mui/icons-material/Monitor";
import MemoryIcon from "@mui/icons-material/Memory";
import AddIcon from "@mui/icons-material/Add";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import EventNoteIcon from "@mui/icons-material/EventNote";
import HandymanIcon from "@mui/icons-material/Handyman";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import AndroidIcon from "@mui/icons-material/Android";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import MouseIcon from "@mui/icons-material/Mouse";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BarChartIcon from "@mui/icons-material/BarChart";
import EditIcon from "@mui/icons-material/Edit";
import StorageIcon from "@mui/icons-material/Storage";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import StyleIcon from "@mui/icons-material/Style";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import JoinFullIcon from "@mui/icons-material/JoinFull";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import config from "../config";

// ==============================|| MENU ITEMS ||============================== //

// const menuItems = {
//   items: [dashboard, produkte, pages, utilities, other, definitionen],
// };

const MenuItems = () => {
  const [items, setItems] = useState([]);
  //const [items, setItems] = useState([dashboard, produkte]);

  const icons = {
    Category: CategoryIcon,
    Coronavirus: CoronavirusIcon,
    CalendarMonth: CalendarMonthIcon,
    Monitor: MonitorIcon,
    Memory: MemoryIcon,
    Add: AddIcon,
    FormatListBulleted: FormatListBulletedIcon,
    EventNote: EventNoteIcon,
    Handyman: HandymanIcon,
    AssignmentTurnedIn: AssignmentTurnedInIcon,
    CompareArrows: CompareArrowsIcon,
    SettingsEthernet: SettingsEthernetIcon,
    QrCode2: QrCode2Icon,
    Android: AndroidIcon,
    LocalShipping: LocalShippingIcon,
    MoveToInbox: MoveToInboxIcon,
    Warehouse: WarehouseIcon,
    Mouse: MouseIcon,
    AttachFile: AttachFileIcon,
    BarChart: BarChartIcon,
    Edit: EditIcon,
    Storage: StorageIcon,
    DeleteForever: DeleteForeverIcon,
    Autorenew: AutorenewIcon,
    Style: StyleIcon,
    Settings: SettingsIcon,
    AdminPanelSettings: AdminPanelSettingsIcon,
    DomainVerification: DomainVerificationIcon,
    JoinFull: JoinFullIcon,
    Assignment: AssignmentIcon,
    Dashboard: DashboardIcon,
  };

  const replaceIcon = (obj) => {
    if (typeof obj === "object") {
      // iterating over the object using for..in
      for (var keys in obj) {
        //checking if the current value is an object itself
        if (typeof obj[keys] === "object") {
          // if so then again calling the same function
          replaceIcon(obj[keys]);
        } else {
          // else replace the icon string with the icon object
          if (keys === "icon") {
            if (obj[keys] in icons) {
              obj[keys] = icons[obj[keys]];
            }
          }
        }
      }
    }
    return obj;
  };

  const myDataUrl = config.getBaseApiUrl() + "/index.php?cmd=menuTree&forReact";
  useEffect(() => {
    fetch(myDataUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        const newData = replaceIcon(data);
        setItems(newData);
      });
  }, []);

  return {
    items: items,
  };
};
export default MenuItems;

import { configureStore } from "@reduxjs/toolkit";
import customizationReducer from "./customizationReducer";

// ==============================|| REDUX - MAIN STORE ||============================== //

export default configureStore({
  reducer: {
    customization: customizationReducer,
  },
});

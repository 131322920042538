import config from "../config";

class authController {
  user;
  observers = [];

  addHandler(observer) {
    this.observers.push(observer);
  }

  removeHandler(observer) {
    const index = this.observers.findIndex((x) => x === observer);
    if (index >= 0) this.observers.splice(index, 1);
  }

  isLoggedIn = () => {
    return this.user !== undefined;
  };

  getUser = () => {
    return this.user;
  };

  logout = async () => {
    try {
      const body = {
        doLogout: true,
      };

      await this.post("loginReact", body);
      this.user = undefined;
      this.sendOnAuthChanged({ user: this.user });
      return true;
    } catch (error) {
      throw error;
    }
  };

  login = async (login, password, persist) => {
    console.log("do login");
    if (login && password)
      return await this.loginWithCredentials(login, password, persist);
    else return await this.loginWithJwt();
  };

  loginWithCredentials = async (login, password, persist) => {
    const body = {
      username: login.toLowerCase().trim(),
      password: password.trim(),
      persist: persist,
    };

    try {
      const response = await this.post("loginReact", body);

      if (response.ok == true) {
        this.user = {
          username: response.username,
          name: response.name,
          userid: response.userid,
          subtitle: response.subtitle,
          role: response.role,
          picture: response.picture,
        };
      } else {
        throw response.errorMsg;
      }

      this.sendOnAuthChanged({ user: this.user });
      return this.user;
    } catch (error) {
      throw error;
    }
  };

  loginWithJwt = async () => {
    try {
      const body = {
        checkCookie: true,
      };

      const response = await this.post("loginReact", body);

      if (response.ok == true) {
        this.user = {
          username: response.username,
          name: response.name,
          userid: response.userid,
          subtitle: response.subtitle,
          role: response.role,
          picture: response.picture,
        };
      } else {
        throw response.errorMsg;
      }

      this.sendOnAuthChanged({ user: this.user });
      return this.user;
    } catch (error) {
      throw error;
    }
  };

  sendOnAuthChanged = (args) => {
    this.observers
      .filter((o) => o.onAuthChanged !== undefined)
      .forEach((o) => o.onAuthChanged(args));
  };

  post = async (endpoint, body) => {
    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    if (body) options.body = JSON.stringify(body);

    let errorMessage = "";

    try {
      const response = await fetch(
        config.getBaseApiUrl() + "/index.php?cmd=" + endpoint,
        options
      );
      let body = null;

      try {
        if (response.status !== 204) body = await response.json();
      } catch (e) {
        console.error(e);
      }

      if (response.status >= 200 && response.status < 300) return body;
      else errorMessage = body?.error?.code;
    } catch (e) {
      console.error(e);
      errorMessage = "ERR_CONNECTION";
    }

    throw new Error(errorMessage);
  };

  // login = () => {
  //     console.log('do login');
  //     return "ok";
  //   }
}

const controller = new authController();
export default controller;

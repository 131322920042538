const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  // basename: "/ml/build",
  basename: "",
  defaultPath: "/dashboard/default",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  getBaseApiUrl: () => {
    let url;
    switch (process.env.NODE_ENV) {
      case "production":
        url = "https://api.lager2.mikka.at";
        break;
      case "development":
      default:
        url = "http://localhost/ml";
    }

    return url;
  },
};

export default config;

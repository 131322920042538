import { lazy } from "react";

// project imports
import MainLayout from "../layout/MainLayout";
import PrivateRoutes from "./PrivateRoutes";
import Loadable from "../ui-component/Loadable";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
);

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import("../views/utilities/Typography"))
);
const UtilsTest1 = Loadable(lazy(() => import("../views/utilities/Test1")));
const UtilsColor = Loadable(lazy(() => import("../views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("../views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("../views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("../views/utilities/TablerIcons"))
);
const DefinitionProductsProductgroup = Loadable(
  lazy(() => import("../views/definitionen/products/productgroup"))
);
const DefinitionComponentGroup = Loadable(
  lazy(() => import("../views/definitionen/components/group"))
);
const DefinitionAccessoriesGroup = Loadable(
  lazy(() => import("../views/definitionen/accessories/group"))
);
const DefinitionDepot = Loadable(
  lazy(() => import("../views/definitionen/depot"))
);
const OldContent = Loadable(
  lazy(() => import("../views/utilities/OldContent"))
);

// sample page routing
const SamplePage = Loadable(lazy(() => import("../views/sample-page")));

const NotFound = Loadable(lazy(() => import("../views/global/NotFound")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  element: <PrivateRoutes />,
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <DashboardDefault />,
        },
        {
          path: "dashboard",
          children: [
            {
              path: "default",
              element: <DashboardDefault />,
            },
          ],
        },
        {
          path: "utils",
          children: [
            {
              path: "util-typography",
              element: <UtilsTypography />,
            },
          ],
        },
        {
          path: "utils",
          children: [
            {
              path: "util-test1",
              element: <UtilsTest1 />,
            },
          ],
        },
        {
          path: "utils",
          children: [
            {
              path: "util-color",
              element: <UtilsColor />,
            },
          ],
        },
        {
          path: "utils",
          children: [
            {
              path: "util-shadow",
              element: <UtilsShadow />,
            },
          ],
        },
        {
          path: "icons",
          children: [
            {
              path: "tabler-icons",
              element: <UtilsTablerIcons />,
            },
          ],
        },
        {
          path: "icons",
          children: [
            {
              path: "material-icons",
              element: <UtilsMaterialIcons />,
            },
          ],
        },
        {
          path: "sample-page",
          element: <SamplePage />,
        },
        {
          path: "definitions",
          children: [
            {
              path: "products",
              children: [
                {
                  path: "groupList",
                  element: <DefinitionProductsProductgroup />,
                },
                {
                  path: "groupDefinition",
                  element: (
                    <OldContent
                      page={"productGroupDefinition"}
                      title={"Produktgruppen definieren"}
                    />
                  ),
                },
                {
                  path: "list",
                  element: (
                    <OldContent page={"productList"} title={"Produktliste"} />
                  ),
                },
                {
                  path: "new",
                  element: (
                    <OldContent
                      page={"productNew"}
                      title={"Produkt erstellen"}
                    />
                  ),
                },
                {
                  path: "definition",
                  element: (
                    <OldContent
                      page={"productDefinition"}
                      title={"Produkte definieren"}
                    />
                  ),
                },
                {
                  path: "variantList",
                  element: (
                    <OldContent
                      page={"productVariantList"}
                      title={"Produktvarianten auflisten"}
                    />
                  ),
                },
                {
                  path: "variantNew",
                  element: (
                    <OldContent
                      page={"productVariantNew"}
                      title={"Produktvariante erstellen"}
                    />
                  ),
                },
                {
                  path: "variantDefinition",
                  element: (
                    <OldContent
                      page={"productVariantDefinition"}
                      title={"Produktvariante definieren"}
                    />
                  ),
                },
              ],
            },
            {
              path: "component",
              children: [
                {
                  path: "groupList",
                  element: <DefinitionComponentGroup />,
                },
                {
                  path: "groupNew",
                  element: (
                    <OldContent
                      page={"componentGroupNew"}
                      title={"Komponenten Gruppen erstellen"}
                    />
                  ),
                },
                {
                  path: "list",
                  element: (
                    <OldContent
                      page={"componentList"}
                      title={"Komponenten (Liste)"}
                    />
                  ),
                },
                {
                  path: "new",
                  element: (
                    <OldContent
                      page={"componentNew"}
                      title={"Komponenten neu anlegen"}
                    />
                  ),
                },
              ],
            },
            {
              path: "accessories",
              children: [
                {
                  path: "groupList",
                  element: <DefinitionAccessoriesGroup />,
                },
                {
                  path: "groupNew",
                  element: (
                    <OldContent
                      page={"accessoriesGroupNew"}
                      title={"Zubehörgruppen erstellen"}
                    />
                  ),
                },
                {
                  path: "list",
                  element: (
                    <OldContent
                      page={"accessoriesList"}
                      title={"Zubehör (Liste)"}
                    />
                  ),
                },
                {
                  path: "new",
                  element: (
                    <OldContent
                      page={"accessoriesNew"}
                      title={"Zubehör erstellen"}
                    />
                  ),
                },
              ],
            },
            {
              path: "depot",
              // element: <OldContent page={"lagerplatz2"} title={"Lagerorte"} />,
              element: <DefinitionDepot />,
            },
          ],
        },
        {
          path: "products",
          children: [
            {
              path: "materialrequest",
              children: [
                {
                  path: "create",
                  element: (
                    <OldContent
                      page={"materialRequest"}
                      title={"Materialanforderung erstellen"}
                    />
                  ),
                },
                {
                  path: "create/:id",
                  element: (
                    <OldContent
                      page={"materialRequest"}
                      title={"Materialanforderung editieren"}
                    />
                  ),
                },
                {
                  path: "list",
                  element: (
                    <OldContent
                      page={"materialRequestList"}
                      title={"Materialanforderungen"}
                    />
                  ),
                },
                {
                  path: "picking",
                  element: (
                    <OldContent
                      page={"materialRequestPicking"}
                      title={"Materialanforderung kommissionieren"}
                    />
                  ),
                },
              ],
            },
            {
              path: "manufacturingjob",
              children: [
                {
                  path: "create",
                  element: (
                    <OldContent
                      page={"manufacturingJob"}
                      title={"Fertigungsauftrag erstellen"}
                    />
                  ),
                },
                {
                  path: "create/:id",
                  element: (
                    <OldContent
                      page={"materialRequest"}
                      title={"Fertigungsauftrag editieren"}
                    />
                  ),
                },
                {
                  path: "list",
                  element: (
                    <OldContent
                      page={"manufacturingJobList"}
                      title={"Fertigungsaufträge"}
                    />
                  ),
                },
                {
                  path: "picking",
                  element: (
                    <OldContent
                      page={"manufacturingJobPicking"}
                      title={"Fertigungsauftrag kommissionieren"}
                    />
                  ),
                },
                {
                  path: "production",
                  element: (
                    <OldContent
                      page={"manufacturingJobProduction"}
                      title={"Fertigungsaufträge - Produktion"}
                    />
                  ),
                },
                {
                  path: "finish",
                  element: (
                    <OldContent
                      page={"manufacturingJobFinish"}
                      title={"Fertigungsauftrag abschließen"}
                    />
                  ),
                },
              ],
            },
            {
              path: "manufacturingJobSchedule",
              element: (
                <OldContent
                  page={"manufacturingJobSchedule"}
                  title={"Produktionsplan"}
                />
              ),
            },
            {
              path: "depotList",
              element: (
                <OldContent
                  page={"depotList"}
                  title={"Lagerstand (Fertigwaren)"}
                />
              ),
            },
            {
              path: "depotList2",
              element: (
                <OldContent page={"depotList2"} title={"Lagerstand (neu)"} />
              ),
            },
            {
              path: "depotMove",
              element: (
                <OldContent page={"depotMove"} title={"Artikel umbuchen"} />
              ),
            },
            {
              path: "depotRemove",
              element: (
                <OldContent
                  page={"depotRemove"}
                  title={"Vom Lager entnehmen"}
                />
              ),
            },
            {
              path: "depotHire",
              element: <OldContent page={"depotHire"} title={"Leihgeräte"} />,
            },
            {
              path: "depotListSerial2",
              element: (
                <OldContent
                  page={"depotListSerial2"}
                  title={"Seriennummern auflisten"}
                />
              ),
            },
            {
              path: "depotAndroidTV",
              element: (
                <OldContent
                  page={"depotAndroidTV"}
                  title={"AndroidTV Labels"}
                />
              ),
            },
            {
              path: "depotCov",
              element: <OldContent page={"depotCov"} title={"CoV Labels"} />,
            },
            {
              path: "componentIncoming",
              element: (
                <OldContent
                  page={"componentIncoming"}
                  title={"Wareneingang (Komponenten)"}
                />
              ),
            },
            {
              path: "accessoriesIncoming",
              element: (
                <OldContent
                  page={"accessoriesIncoming"}
                  title={"Wareneingang (Zubehör)"}
                />
              ),
            },
          ],
        },
        {
          path: "admin",
          children: [
            {
              path: "renameId",
              element: (
                <OldContent page={"adminRenameID"} title={"IDs umbenennen"} />
              ),
            },
            {
              path: "dbCheck",
              element: (
                <OldContent page={"adminDBcheck"} title={"Datenbank prüfen"} />
              ),
            },
            {
              path: "deleteSerial",
              element: (
                <OldContent
                  page={"adminDeleteSerial"}
                  title={"Seriennummer löschen"}
                />
              ),
            },
            {
              path: "deleteId",
              element: (
                <OldContent
                  page={"adminDeleteID"}
                  title={"Artikel deaktivieren / löschen"}
                />
              ),
            },
            {
              path: "recreateSnLabel",
              element: (
                <OldContent
                  page={"adminRecreateSNLabel"}
                  title={"SN Label neu erstellen"}
                />
              ),
            },
            {
              path: "importLager",
              element: (
                <OldContent
                  page={"importLager"}
                  title={"Wareneingang via CSV"}
                />
              ),
            },
            {
              path: "componentPropList",
              element: (
                <OldContent
                  page={"adminComponentPropList"}
                  title={"Komponenten Eigenschaften"}
                />
              ),
            },
          ],
        },
        {
          path: "transaction",
          children: [
            {
              path: "product",
              element: (
                <OldContent
                  page={"transactionProduct"}
                  title={"Lagerbewegung: Produkte"}
                />
              ),
            },
            {
              path: "depot",
              element: (
                <OldContent
                  page={"depotTransaction"}
                  title={"Lagerbewegung nach Lagerort"}
                />
              ),
            },
            {
              path: "component",
              element: (
                <OldContent
                  page={"componentTransaction"}
                  title={"Lagerbewegung: Komponenten"}
                />
              ),
            },
            {
              path: "accessories",
              element: (
                <OldContent
                  page={"accessoriesTransaction"}
                  title={"Lagerbewegung: Zubehör"}
                />
              ),
            },
          ],
        },
        {
          path: "depot",
          children: [
            {
              path: "komm",
              element: (
                <OldContent
                  page={"depotKomm"}
                  title={"Lagerkontrolle: Kommissionier-Lager"}
                />
              ),
            },
            {
              path: "csv",
              element: (
                <OldContent
                  page={"depotCheck"}
                  title={"Lagerkontrolle: Kontolle via CSV"}
                />
              ),
            },
            {
              path: "requirement",
              element: (
                <OldContent
                  page={"depotRequirement"}
                  title={"Lagerkontrolle: Materialbedarf"}
                />
              ),
            },
          ],
        },

        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ],
};

// const MainRoutes = {
//   path: "/",
//   element: <MainLayout />,
//   children: [
//     {
//       path: "/",
//       element: <DashboardDefault />,
//     },
//     {
//       path: "dashboard",
//       children: [
//         {
//           path: "default",
//           element: <DashboardDefault />,
//         },
//       ],
//     },
//     {
//       path: "utils",
//       children: [
//         {
//           path: "util-typography",
//           element: <UtilsTypography />,
//         },
//       ],
//     },
//     {
//       path: "utils",
//       children: [
//         {
//           path: "util-test1",
//           element: <UtilsTest1 />,
//         },
//       ],
//     },
//     {
//       path: "utils",
//       children: [
//         {
//           path: "util-color",
//           element: <UtilsColor />,
//         },
//       ],
//     },
//     {
//       path: "utils",
//       children: [
//         {
//           path: "util-shadow",
//           element: <UtilsShadow />,
//         },
//       ],
//     },
//     {
//       path: "icons",
//       children: [
//         {
//           path: "tabler-icons",
//           element: <UtilsTablerIcons />,
//         },
//       ],
//     },
//     {
//       path: "icons",
//       children: [
//         {
//           path: "material-icons",
//           element: <UtilsMaterialIcons />,
//         },
//       ],
//     },
//     {
//       path: "sample-page",
//       element: <SamplePage />,
//     },
//     {
//       path: "definitions",
//       children: [
//         {
//           path: "products",
//           children: [
//             {
//               path: "productgroup",
//               element: <DefinitionProductsProductgroup />,
//             },
//             {
//               path: "productlist",
//               element: <DefinitionProductsProductlist />,
//             },
//           ],
//         },
//       ],
//     },
//     {
//       path: "products",
//       children: [
//         {
//           path: "materialrequest",
//           children: [
//             {
//               path: "create",
//               element: (
//                 <OldContent
//                   page={"materialRequest"}
//                   title={"Materialanforderung erstellen"}
//                 />
//               ),
//             },
//             {
//               path: "create/:id",
//               element: (
//                 <OldContent
//                   page={"materialRequest"}
//                   title={"Materialanforderung editieren"}
//                 />
//               ),
//             },
//             {
//               path: "list",
//               element: (
//                 <OldContent
//                   page={"materialRequestList"}
//                   title={"Materialanforderungen"}
//                 />
//               ),
//             },
//             {
//               path: "picking",
//               element: (
//                 <OldContent
//                   page={"materialRequestPicking"}
//                   title={"Materialanforderung kommissionieren"}
//                 />
//               ),
//             },
//           ],
//         },
//         {
//           path: "manufacturingjob",
//           children: [
//             {
//               path: "create",
//               element: (
//                 <OldContent
//                   page={"manufacturingJob"}
//                   title={"Fertigungsauftrag erstellen"}
//                 />
//               ),
//             },
//             {
//               path: "list",
//               element: (
//                 <OldContent
//                   page={"manufacturingJobList"}
//                   title={"Fertigungsaufträge"}
//                 />
//               ),
//             },
//             {
//               path: "picking",
//               element: (
//                 <OldContent
//                   page={"manufacturingJobPicking"}
//                   title={"Fertigungsauftrag kommissionieren"}
//                 />
//               ),
//             },
//             {
//               path: "production",
//               element: (
//                 <OldContent
//                   page={"manufacturingJobProduction"}
//                   title={"Fertigungsaufträge - Produktion"}
//                 />
//               ),
//             },
//             {
//               path: "finish",
//               element: (
//                 <OldContent
//                   page={"manufacturingJobFinish"}
//                   title={"Fertigungsauftrag abschließen"}
//                 />
//               ),
//             },
//           ],
//         },
//         {
//           path: "manufacturingJobSchedule",
//           element: (
//             <OldContent
//               page={"manufacturingJobSchedule"}
//               title={"Produktionsplan"}
//             />
//           ),
//         },
//         {
//           path: "depotList",
//           element: (
//             <OldContent page={"depotList"} title={"Lagerstand (Fertigwaren)"} />
//           ),
//         },
//         {
//           path: "depotList2",
//           element: (
//             <OldContent page={"depotList2"} title={"Lagerstand (neu)"} />
//           ),
//         },
//         {
//           path: "depotMove",
//           element: <OldContent page={"depotMove"} title={"Artikel umbuchen"} />,
//         },
//         {
//           path: "depotRemove",
//           element: (
//             <OldContent page={"depotRemove"} title={"Vom Lager entnehmen"} />
//           ),
//         },
//         {
//           path: "depotHire",
//           element: <OldContent page={"depotHire"} title={"Leihgeräte"} />,
//         },
//         {
//           path: "depotListSerial2",
//           element: (
//             <OldContent
//               page={"depotListSerial2"}
//               title={"Seriennummern auflisten"}
//             />
//           ),
//         },
//         {
//           path: "depotAndroidTV",
//           element: (
//             <OldContent page={"depotAndroidTV"} title={"AndroidTV Labels"} />
//           ),
//         },
//         {
//           path: "depotCov",
//           element: <OldContent page={"depotCov"} title={"CoV Labels"} />,
//         },
//         {
//           path: "componentIncoming",
//           element: (
//             <OldContent
//               page={"componentIncoming"}
//               title={"Wareneingang (Komponenten)"}
//             />
//           ),
//         },
//         {
//           path: "accessoriesIncoming",
//           element: (
//             <OldContent
//               page={"accessoriesIncoming"}
//               title={"Wareneingang (Zubehör)"}
//             />
//           ),
//         },
//       ],
//     },
//     {
//       path: "admin",
//       children: [
//         {
//           path: "renameId",
//           element: (
//             <OldContent page={"adminRenameID"} title={"IDs umbenennen"} />
//           ),
//         },
//         {
//           path: "dbCheck",
//           element: (
//             <OldContent page={"adminDBcheck"} title={"Datenbank prüfen"} />
//           ),
//         },
//         {
//           path: "deleteSerial",
//           element: (
//             <OldContent
//               page={"adminDeleteSerial"}
//               title={"Seriennummer löschen"}
//             />
//           ),
//         },
//         {
//           path: "deleteId",
//           element: (
//             <OldContent
//               page={"adminDeleteID"}
//               title={"Artikel deaktivieren / löschen"}
//             />
//           ),
//         },
//         {
//           path: "recreateSnLabel",
//           element: (
//             <OldContent
//               page={"adminRecreateSNLabel"}
//               title={"SN Label neu erstellen"}
//             />
//           ),
//         },
//         {
//           path: "importLager",
//           element: (
//             <OldContent page={"importLager"} title={"Wareneingang via CSV"} />
//           ),
//         },
//         {
//           path: "componentPropList",
//           element: (
//             <OldContent
//               page={"adminComponentPropList"}
//               title={"Komponenten Eigenschaften"}
//             />
//           ),
//         },
//       ],
//     },
//     {
//       path: "transaction",
//       children: [
//         {
//           path: "product",
//           element: (
//             <OldContent
//               page={"transactionProduct"}
//               title={"Lagerbewegung: Produkte"}
//             />
//           ),
//         },
//         {
//           path: "depot",
//           element: (
//             <OldContent
//               page={"depotTransaction"}
//               title={"Lagerbewegung nach Lagerort"}
//             />
//           ),
//         },
//         {
//           path: "component",
//           element: (
//             <OldContent
//               page={"componentTransaction"}
//               title={"Lagerbewegung: Komponenten"}
//             />
//           ),
//         },
//         {
//           path: "accessories",
//           element: (
//             <OldContent
//               page={"accessoriesTransaction"}
//               title={"Lagerbewegung: Zubehör"}
//             />
//           ),
//         },
//       ],
//     },
//     {
//       path: "depot",
//       children: [
//         {
//           path: "komm",
//           element: (
//             <OldContent
//               page={"depotKomm"}
//               title={"Lagerkontrolle: Kommissionier-Lager"}
//             />
//           ),
//         },
//         {
//           path: "csv",
//           element: (
//             <OldContent
//               page={"depotCheck"}
//               title={"Lagerkontrolle: Kontolle via CSV"}
//             />
//           ),
//         },
//         {
//           path: "requirement",
//           element: (
//             <OldContent
//               page={"depotRequirement"}
//               title={"Lagerkontrolle: Materialbedarf"}
//             />
//           ),
//         },
//       ],
//     },
//
//     {
//       path: "*",
//       element: <NotFound />,
//     },
//   ],
// };

export default MainRoutes;

import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// project imports
import NavItem from "../NavItem";
import NavCollapse from "../NavCollapse";

import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import { useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import * as customization from "@mui/system";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? item.id : null);
  };

  // menu list collapse & items
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case "collapse":
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case "item":
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = item.icon;
  const menuIcon = item.icon ? (
    <Icon
      strokeWidth={1.5}
      size="1.3rem"
      style={{
        marginTop: "auto",
        marginBottom: "auto",
      }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === item.id ? 8 : 6,
        height: selected === item.id ? 8 : 6,
      }}
      fontSize={"medium"}
    />
  );

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `${customization.borderRadius}px`,
          mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: "transparent !important",
          py: 1.25,
          pl: `0px`,
        }}
        selected={selected === item.id}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ my: "auto", minWidth: !item.icon ? 18 : 36 }}>
          {menuIcon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant={selected === item.id ? "h5" : "body1"}
              color="inherit"
              sx={{ my: "auto" }}
            >
              {item.title}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography
                variant="caption"
                sx={{ ...theme.typography.subMenuCaption }}
                display="block"
                gutterBottom
              >
                {item.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp
            stroke={1.5}
            size="1rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          />
        ) : (
          <IconChevronDown
            stroke={1.5}
            size="1rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            position: "relative",
            "&:after": {
              content: "''",
              position: "absolute",
              left: "10px",
              top: 0,
              height: "100%",
              width: "1px",
              opacity: 1,
              background: theme.palette.menu.lineLeft,
            },
          }}
        >
          {items}
        </List>

        {/* group divider */}
        <Divider
          sx={{
            mt: 0.25,
            mb: 1.25,
            borderColor: theme.palette.menu.lineDivider,
          }}
        />
      </Collapse>
    </>
  );

  // return (
  //   <>
  //     <List
  //       subheader={
  //         item.title && (
  //           <Typography
  //             variant="caption"
  //             sx={{ ...theme.typography.menuCaption }}
  //             display="block"
  //             gutterBottom
  //           >
  //             {item.title}
  //             {item.caption && (
  //               <Typography
  //                 variant="caption"
  //                 sx={{ ...theme.typography.subMenuCaption }}
  //                 display="block"
  //                 gutterBottom
  //               >
  //                 {item.caption}
  //               </Typography>
  //             )}
  //           </Typography>
  //         )
  //       }
  //     >
  //       {items}
  //     </List>
  //
  //     {/* group divider */}
  //     <Divider sx={{ mt: 0.25, mb: 1.25 }} />
  //   </>
  // );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;

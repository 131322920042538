import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import config from "../../../config";
import Logo from "../../../ui-component/Logo";
import Box from "@mui/material/Box";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  return (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
      <Box overflow="hidden" textOverflow="ellipsis">
        <Logo height={30} color={"white"} />
      </Box>
    </ButtonBase>
  );
};

export default LogoSection;
